<template>

  <header class="kb-header" id="kb-header">
    <div class="header-component"><!-- header-title -->
      <div class="header-column header-title">설문</div>
      <div class="header-column header-util left">
        <div class="util util-back">
<!--          <router-link :to="{name: 'Main'}" class="util-actions util-actions-back">-->
<!--            <i class="icon-history-back"></i> 메인으로-->
<!--          </router-link>-->
        </div>

      </div>
    </div><!-- //header-util -->
  </header>
  <main class="kb-main main-center" v-if="pageMsg">
    <div class="main-content">
      <div class="error-page mt-6 mb-6">
        <div class="images">
          <img src="@/assets/lxp/images/common/loading_01.svg" alt=""/>
        </div>
        <p class="description">
          {{pageMsg}}
          <br><br>인터넷 브라우저를 종료해주시기 바랍니다.
        </p>
      </div>
    </div>
  </main>

  <main class="kb-main" id="kb-myclass" v-else>
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="content-section-group content-section-survey">
        <div>
          <header class="group-header">
            <h3 v-if="isReady" class="title text-primary text-center">{{dist.crseNm}}</h3>
          </header>
          <header v-if="isReady" class="group-header mt-3 mb-3">
            <h1>[설문안내]</h1>
            <h3 class="title text-md-center text-left" style="white-space:break-spaces">{{svys[0].svyCn}}</h3>
          </header>
          <!-- content-section -->
          <template v-for="(svy, index) in svys" :key="index">
            <div v-if="index > 0" class="mt-5 mb-5 kb-border-top-underline" ></div>

            <article v-if="svy.tmtblSvyYn === 'Y'" class="content-section section-margin">
              <header class="section-header header-divider">
                <h3 class="title font-body16B">강의정보</h3>
              </header>
              <!-- text-item-container -->
              <div class="text-item-container">
                <!-- text-item -->
                <div class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">강의명</h4>
                      <div class="item-meta fsize-m">
                        <span class="text">{{ svy.objNm }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- text-item -->
                <div v-if="svy.chapNm" class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">챕터명</h4>
                      <div class="item-meta fsize-m">
                        <span class="text">{{ svy.chapNm }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- text-item -->
                <div class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">강사</h4>
                      <div class="item-meta fsize-m">
                        <span class="text">{{ getTutors(svy, '-') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- text-item -->
                <div class="text-item">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <h4 class="item-subtitle">일정</h4>
                      <div class="item-meta fsize-m">
                        <span class="text">{{ timestampToDateFormat(svy.objBgngDt, 'yyyy.MM.dd hh:mm') }} - {{timestampToDateFormat(svy.objEndDt, 'yyyy.MM.dd hh:mm')}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //text-item -->
              </div>
              <!-- //text-item-container -->
            </article>

            <template v-if="svy.parts && svy.parts.length > 0">
              <template v-for="(part, idx) in svy.parts" :key="`${index}-${idx}`">
                <div v-if="part.svyPartNm" class="survey-article survey-notice mt-4">
                  <h3 class="title">{{  part.svyPartNm }}</h3>
                </div>
                
                <template v-for="(question, i) in part.questions" :key="`${index}-${idx}-${i}`">
                  <article v-if="question.isShow" class="survey-article">
                  <header class="survey-header">
                    <h4 class="title">{{ getQuestionNo(svy.parts, idx, i) }}. {{ question.qstnNm }}</h4>
                  </header>

                  <template v-if="selectTypes.includes(question.qstnKindCdDcd)">
                    <div v-if="question.etcAnsYn === 'Y'" class="survey-checklist-th">
                      <span class="label"></span>
                      <button class="kb-btn-text" @click="toggleEtc(question)">
                        <span class="text text-primary">{{ question.etc ? '취소' : '기타입력' }}</span>
                      </button>
                    </div>

                    <div class="survey-checklist">
                      <!-- kb-form-check-group -->
                      <div class="kb-form-check-group">
                        <!-- kb-form-check -->
                        <div v-for="(option, j) in question.options" class="kb-form-check" :key="`${index}-${idx}-${i}-${j}`">
                          <input
                              v-if="question.qstnKindCdDcd === '2020002'"
                              v-model="question.model"
                              type="checkbox"
                              class="kb-form-check-input"
                              :name="`chk-${index}-${idx}-${i}-${j}`"
                              :id="`chk-${index}-${idx}-${i}-${j}`"
                              :value="option.optNo"
                              :disabled="!!question.etc"
                          >
                          <input
                              v-else
                              v-model="question.model"
                              type="radio"
                              class="kb-form-check-input"
                              :name="`chk-${index}-${idx}-${i}`"
                              :id="`chk-${index}-${idx}-${i}-${j}`"
                              :value="option.optNo"
                              :disabled="!!question.etc"
                              @click="chkLink(index, part)"
                          >
                          <label :for="`chk-${index}-${idx}-${i}-${j}`" class="kb-form-check-label">{{ option.optCn }}</label>
                        </div>
                      </div>
                    </div>

                    <div v-if="question.etc" class="survey-textarea">
                      <textarea v-model.trim="question.etcAns" class="kb-form-control" placeholder="기타 입력"></textarea>
                    </div>
                  </template>
                  <div v-else class="survey-textarea">
                    <textarea v-model="question.model" class="kb-form-control" placeholder="내용을 입력해주시기 바랍니다."></textarea>
                  </div>
                  <div v-if="question.invalidated" class="survey-feedback">
                    <p class="text text-red"><i class="icon-feedback"></i> 필수 항목 입니다</p>
                  </div>
                </article>
                </template>
              </template>
            </template>
          </template>

          <div class="page-buttons">
            <button class="kb-btn kb-btn-primary" @click="submitSurveys"><span class="text">설문 제출</span></button>
          </div>
        </div>
      </div>

    </div>
    <!-- //main-content -->
  </main>
</template>
<script>

import {surveyTimetableSetup} from "@/assets/js/modules/survey/svy-timetable-setup";

export default {
  name: 'MobileTimetableSurvey',
  setup: surveyTimetableSetup
};
</script>

<style scoped>
.lxp-mobile-layout .text-primary {
  font-size: x-large;
}
</style>